.App {
  text-align: center;
  background-color: #000;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(12px + 2vmin);
  color: white;
  padding: 10px 20px;
  /* Add padding on the sides */
}

.App-link {
  color: #61dafb;
}

.hamburger {
  display: none;
  /* Hidden by default */
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 20;
}

.hamburger-line {
  width: 30px;
  height: 3px;
  background: white;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

h1 {
  font-family: 'Playfair Display', serif;
  color: white;
  margin: 0;
  /* Reset margins to ensure proper alignment */
  padding-right: 20px;
  margin-left: 1em;
  /* Optional: Add if you want more space between the title and nav */
}

/* Smaller screens (default) */
h1 {
  font-size: 16px;
  /* Default font size for small screens */
}

@media (max-width: 768px) {

  .reset-button {
    width: 90%;
  }

  .stepper-controls {
    flex-direction: column;
    gap: 20px;
    /* Adjust gap between buttons */
  }

  .stepper-controls button {
    width: 90%;
    /* Full width buttons on small screens */
    margin: 10px 0;
    /* Adjust margin for vertical stacking */
    padding: 10px;
    /* Adjust padding if needed */
    font-size: 14px;
    /* Adjust font size for small screens */
  }

  .feature-item {
    padding: 8px;
    /* Smaller padding for small screens */
    font-size: 14px;
    /* Adjust font size for smaller screens */
    margin: 5px auto;
    /* Smaller margins for small screens */
  }

  .color-block {
    width: 30px;
    /* Smaller size for small screens */
    height: 30px;
    /* Smaller size for small screens */
    margin: 3px;
    /* Smaller margin for small screens */
  }

  .color-swatch {
    padding: 8px;
    /* Smaller padding for small screens */
  }

  .designer-instructions {
    padding: 10px;
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }

  .designer-instructions h2 {
    font-size: 16px;
    /* Adjust heading size for smaller screens */
  }

  .designer-instructions ol,
  .designer-instructions ul {
    padding-left: 15px;
    /* Adjust padding for list elements */
    padding-right: 15px;
  }

  .designer-item {
    padding: 10px;
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }

  .hamburger {
    display: flex;
  }

  nav {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transition: transform 0.3s ease-in-out;
  }

  nav.active {
    display: flex;
    transform: translateX(100%);
    background: rgba(255, 255, 255, 1.0);
  }
  nav.active a {
    color: black !important; /* Change link color to black */
    /* Other existing styles */
}

  /* Style your nav links for mobile here */
  nav a {
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 20px;
    margin-bottom: 10px;
    /* Spacing between links */
    transition: color 0.3s ease;
    text-decoration: none;
    display: block;
  }

  nav a:hover {
    color: #61dafb;
  }
}

/* Medium screens */
@media (min-width: 768px) {
  h1 {
    font-size: 28px;
    /* Larger font size for medium screens */
  }
}

/* Large screens */
@media (min-width: 1024px) {
  h1 {
    font-size: 36px;
    /* Even larger font size for large screens */
  }
}

/* Extra large screens */
@media (min-width: 1280px) {
  h1 {
    font-size: 44px;
    /* Largest font size for extra large screens */
  }
}


.return_section {
  min-height: 100vh;
}

.return_section h3 {
  font-size: calc(16px + 2vmin);

  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Form Styling */
form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 20px auto;
  color: #333;
}

form div {
  margin-bottom: 15px;
  text-align: left;
}

form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

form input[type="text"],
form input[type="email"],
form input[type="tel"],
form textarea,
form select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

form textarea {
  resize: vertical;
  min-height: 80px;
}

form button {
  background-color: #61dafb;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

form button:hover {
  background-color: #4fa9d6;
}

.designer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  max-width: 600px;
  /* Set a maximum width, adjust as needed */
  width: 90%;
  padding: 10px;
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  margin: 0 auto;

}

.designer-item {
  flex: 1;
  /* Each item will take up equal space */
  min-width: 300px;
  max-width: 800px;
  /* Set a maximum width, adjust as needed */
  width: 90%;
  padding: 10px;
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  margin: 0 auto;
  /* Center the element */
  /* Minimum width of each item before wrapping */
  display: flex;
  flex-direction: column;
}

.designer-item>* {
  flex-grow: 1;
  /* Add additional styles as needed for alignment, padding, etc. */
}

.designer-instructions {
  margin: 20px auto;
  /* Center the div and add spacing around it */
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 600px;
  /* Set a maximum width */
  width: 90%;
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  /* Ensures it takes up the full width up to the max width */
}

.designer-instructions h2 {
  color: #333;
}

.designer-instructions ol {
  padding-left: 20px;
}

.designer-instructions li {
  margin-bottom: 10px;
}


.feature-item {
  background-color: #f8f8f8;
  padding: 10px;
  margin: 10px auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #333;
  max-width: 600px;
  width: 90%;
  box-sizing: border-box;
}

.feature-checkbox {
  margin-right: 10px;
  /* Spacing between checkbox and label */
  flex-shrink: 0;
  /* Prevent checkbox from shrinking */
}

.feature-label {
  margin-left: 5px;
  /* Additional spacing, if needed */
  font-size: 16px;
  /* Adjust font size for readability */
  cursor: pointer;
}

/* Styling for when a feature is checked */
.feature-item input[type="checkbox"]:checked+.feature-label {
  color: #61dafb;
  /* Highlight color when feature is active */
  font-weight: bold;
  /* Make the label bold when checked */
}

.color-swatch {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  max-width: 600px;
  width: 90%;
  box-sizing: border-box;
}

.color-block {
  width: 40px;
  height: 40px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #ccc;
  /* Neutral border for better visibility */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Light shadow for each color block */
  transition: transform 0.2s ease-in-out;
  /* Smooth transition for interaction */
}

.color-block:hover {
  transform: scale(1.1);
  /* Slightly enlarge on hover for interactivity */
}

.color-block.selected {
  border-color: #61dafb;
  /* More noticeable border color for selected state */
  box-shadow: 0 0 8px #61dafb;
  /* Glow effect for selected color */
}

/* Button Styling */
button {
  background-color: #3a93c0;
  /* Primary color */
  color: white;
  /* Text color */
  padding: 10px 20px;
  /* Padding for size */
  border: none;
  /* Remove default border */
  border-radius: 5px;
  /* Rounded corners */
  font-size: 16px;
  /* Font size */
  font-weight: bold;
  /* Font weight */
  cursor: pointer;
  /* Cursor style */
  outline: none;
  /* Remove focus outline */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* Shadow for depth */
  transition: all 0.3s ease;
  /* Transition for interactions */
  margin: 5px;
  /* Spacing around buttons */
}

button:hover,
button:focus {
  background-color: #2a6f8a;
  /* Darker shade on hover/focus */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  /* Larger shadow on hover/focus */
  transform: translateY(-2px);
  /* Slight lift effect */
}

.button-active {
  background-color: #1f5870;
  /* Even darker shade on active */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* Reset shadow on active */
  transform: translateY(0);
  /* Reset position on active */
}

/* Button Container */
.button-container {
  text-align: center;
  /* Center buttons */
  padding: 20px;
  /* Spacing around container */
}

.component-spacing {
  margin-bottom: 20px;
}

.breadcrumb {
  /* General breadcrumb link styles */
  padding: 5px;
  text-decoration: none;
  color: white;
  /* Adjust color to fit your design */
}

.breadcrumb:hover {
  text-decoration: underline;
}

.active-breadcrumb {
  /* Styles for the active breadcrumb */
  color: #61dafb;
  /* Highlight color on hover or focus */
  font-weight: bold;
  cursor: default;
  /* Change cursor to indicate non-clickability */
}

nav {
  padding: 10px 0;
  text-align: center;
}

/* Navigation Link Styles */
nav a {
  text-decoration: none;
  font-family: 'Karla', sans-serif;
  letter-spacing: .1rem;
  font-size: 14px;
  color: white;
  /* Use a light color for contrast against the dark header */
  margin: 0 15px;
  /* Increase margin for more space */
  padding: 5px 10px;
  transition: color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  /* Rounded corners for the background highlight */

}

nav a:hover,
nav a:focus {
  color: #61dafb;
  /* Highlight color on hover or focus */
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
  /* Subtle background on hover */
  transform: translateY(-2px);
  /* Slightly raise the link */
}

nav a.active-link {
  /* Active link color */
  font-weight: bold;
  /* Make active link bold */
  /* Background to indicate active page */
}

.faq {
  background-color: #282c34;
  /* Dark background */
  color: #fff;
  /* Light text for contrast */
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  /* Adjust as per layout */
  margin: 20px auto;
  font-family: 'Arial', sans-serif;
  /* Use your preferred font */
}

.faq h1 {
  color: #61dafb;
  /* A lighter, contrasting color for headings */
  margin-bottom: 20px;
}

.faq h2 {
  color: #4fa9d6;
  /* Slightly darker than h1 for sub-headings */
  margin-top: 18px;
}

.faq p {
  font-size: 16px;
  /* Adjust size as needed */
  line-height: 1.6;
  /* For better readability */
  margin-bottom: 15px;
}

.faq ul {
  list-style: inside square;
  /* Style for list items */
  padding-left: 20px;
  margin-bottom: 20px;
}

.faq ul li {
  margin-bottom: 10px;
  line-height: 1.6;
}

/* If you have links in your FAQ */
.faq a {
  color: #4fd1c5;
  /* Color for links */
  text-decoration: none;
}

.faq a:hover {
  text-decoration: underline;
}

.whiteText {
  color: white;
}

.designer-stepper {
  margin-top: 3em;
}

.stepper-controls button {
  background-color: #61dafb;
  /* Primary color */
  color: white;
  /* Text color */
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
  font-size: 16px;
  /* Adjust size as needed */
  /* Spacing between buttons */
}

.stepper-controls button:hover {
  background-color: #4fa9d6;
  /* Darker shade on hover */
  transform: translateY(-2px);
  /* Slight lift effect */
}

.stepper-footer .reset-button {
  background-color: #61dafb;
  /* Subdued color for reset button */
  color: white;
  padding: 10px 15px;
  border: 1px solid #ccc;
  /* Distinguished styled border */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
  font-size: 16px;
  /* Adjust size as needed */
}

.stepper-footer .reset-button:hover {
  background-color: #2a6f8a;
  /* Slightly darker on hover for interaction feedback */
  transform: translateY(-2px);
  /* Slight lift effect */
}